<template>
   <div :id="adUnitId" v-bind="$attrs"/>
</template>


<script setup lang="ts">

   import {useGoogleAds} from '~/composables/useGoogleAds';

   const {showAd} = useGoogleAds();

   const props = defineProps({
      googleAd: {
         type: Object,
      },
      adUnitId: {
         type: String,
      },
   });

   onMounted(() => {
      if (isEmpty(props.googleAd)) {
         return;
      }
      showAd(props.googleAd);
   });


</script>


<style scoped>

</style>